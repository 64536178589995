import axios from 'axios';

const baseURL = 'https://jaepclasicas.com'; // Replace with your WooCommerce store URL
const consumerKey = 'ck_58e9989010e8b79d3eff72dd710ca2b7107bb8cb'; // Replace with your WooCommerce Consumer Key
const consumerSecret = 'cs_26439ab91d6ad8d6ea3ab8d65fdd10742c06d1d3'; // Replace with your WooCommerce Consumer Secret

const WooCommerce = axios.create({
  baseURL,
  auth: {
    username: consumerKey,
    password: consumerSecret,
  },
});

export const addToCart = async (productId, quantity) => {
  try {
    // Make the API call to add the product to the cart
    const response = await WooCommerce.post('/wc-api/v1/cart/add', {
      product_id: productId,
      quantity: quantity,
    });

    // Return the response data
    return response.data;
  } catch (error) {
    // Throw an error if the product couldn't be added to the cart.
    throw new Error('Failed to add product to cart: ' + error.message);
  }
};

export default WooCommerce;
