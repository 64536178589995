// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import the necessary components from react-router-dom

import ProductsList from './components/ProductsList';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<ProductsList />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
