import React, { useState, useEffect } from 'react';
import WooCommerce from './backend-api';
import '../components/css/ProductsList.css';

const ProductsList = () => {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await WooCommerce.get('/wc-api/v1/products');
        setProducts(response.data.products);
      } catch (error) {
        setError('Error fetching products. Please try again later.');
      }
    };

    fetchProducts();
  }, []);

  const handleAddToCart = async (productId) => {
    try {
      // Make the API call to add the product to the cart
      await WooCommerce.post('/wc-api/v1/cart/add', {
        product_id: productId,
        quantity: 1,
      });

      // Optionally, you can show a success message here if needed.
      console.log('Product added to cart successfully!');

      // Update the cart items state
      setCartItems((prevCartItems) => [...prevCartItems, productId]);
    } catch (error) {
      // Handle errors here if the product couldn't be added to the cart.
      console.error('Failed to add product to cart:', error.message);
    }
  };

  return (
    <div className="product-container">
      <h2>Products</h2>
      {error ? (
        <p>{error}</p>
      ) : (
        <ul className="product-grid">
          {products.map((product) => (
            <li className="product-item" key={product.id}>
              <h3>{product.title}</h3>
              <p>Price: ${product.price}</p>
              <p>Description: {product.description}</p>
              {/* Display the images */}
              {product.images.map((image) => (
                <img
                  className="product-image"
                  key={image.id}
                  src={image.src}
                  alt={product.title}
                />
              ))}
              <button
                className="add-to-cart-button"
                onClick={() => handleAddToCart(product.id)}
              >
                Add to Cart
              </button>
            </li>
          ))}
        </ul>
      )}
      {/* Display the shopping cart items */}
      <div className="cart-container">
        <h2>Shopping Cart</h2>
        <ul className="cart-items">
          {cartItems.map((itemId) => {
            // Find the product in the products array using the itemId
            const cartProduct = products.find((product) => product.id === itemId);
            return (
              <li key={itemId}>
                <h3>{cartProduct.title}</h3>
                <p>Price: ${cartProduct.price}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ProductsList;
